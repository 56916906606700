import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import * as profile from './profile'
import router from '../router/index'
import { v4 as uuidv4 } from 'uuid'
import { formatDistanceToNow } from 'date-fns'
import { getAuthenticatedUser } from '@/firebase'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userProfile: {},
    history: [],
    sort: null,
    pageTitle: '',
    contacts: [],
    listInfo: {},
    listItems: [],
    historyQuery: '',
    sharedLists: [],
    sharedList: {},
    status: '',
    error: ''
  },
  mutations: {
    initialiseStore(state) {
			const profile = JSON.parse(localStorage.getItem('profile'))
      const sort = JSON.parse(localStorage.getItem('sort'))
      state.userProfile = profile ? profile : {}
      state.sort = sort ? sort : null
		},
    setUserProfile(state, val) {
      state.userProfile = val
      localStorage.setItem('profile', JSON.stringify(val))
    },
    setHistory(state, val) {
      state.history = val
    },
    setSort(state, val) {
      state.sort = val
      localStorage.setItem('sort', JSON.stringify(val))
    },
    setPageTitle(state, val) {
      state.pageTitle = val
    },
    setHistoryQuery(state, val) {
      state.historyQuery = val
    },
    setListInfo(state, val) {
      state.listInfo = val
    },
    setListItems(state, val) {
      state.listItems = val
    },
    setSharedLists(state, value) {
      state.sharedLists = value
    },
    setSharedList(state, value) {
      state.sharedList = value
    },
    setContacts(stage, val) {
      stage.contacts = val
    },
    setStatus(state, val) {
      state.status = val
    },
    setError (state, val) {
      state.error = val
    }
  },
  actions: {
    async login({ commit }, form) {
      try {
        const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
        commit('setUserProfile', {
          uid: user.uid,
          email: user.email
        })
        router.push('/')
      } catch (err) {
        commit('setError', 'Invalid email or password')
      }
    },
    async logout() {
      await fb.auth.signOut()
      router.push('/')
      window.location.reload()
    },
    async fetchHistory () {
      const ref = fb.db.collection('accounts').doc(profile.uid())
      ref.onSnapshot(doc => {
        if (doc.exists) {
          store.commit('setHistory', doc.data().history)
        }
        else {
          // no history exists for this user. Initialise one
          fb.db.collection('accounts').doc(profile.uid()).set({
            contacts: [],
            history: []
          })
        }
      })
    },
    async fetchContacts () {
      const ref = fb.db.collection('accounts').doc(profile.uid())
      ref.onSnapshot(doc => {
        if (doc.exists) {
          store.commit('setContacts', doc.data().contacts)
        }
      })
    },
    async fetchList ({dispatch}) {
      const ref = fb.db.collection('accounts').doc(profile.uid())
      ref.onSnapshot(doc => {
        if (doc.exists && doc.data().list) {
          store.commit('setListInfo', {
            id: doc.data().list.id,
            name: doc.data().list.name
          })
          store.commit('setListItems', doc.data().list.items)
        } else {
          dispatch ('resetList')
        }
      })
    },
    async fetchSharedLists () {
      fb.db.collection('accounts').doc(profile.uid()).collection('shared').orderBy("sharedAt", "desc").limit(50).get().then((querySnapshot) => {
        let result = []
        querySnapshot.forEach((doc) => {
          result.push({
            id: doc.id,
            name: doc.data().name,
            sharedAt: formatDistanceToNow(doc.data().sharedAt.toDate(),{includeSeconds: true}),
            sharedWith: doc.data().sharedWith
          })
        })
        store.commit('setSharedLists', result)
      })
    },
    async fetchSharedList ({ commit }, id) {
      const ref = fb.db.collection('share').doc(id)
      ref.onSnapshot(doc => {
        if (doc.exists) {
          commit('setSharedList', doc.data())
        }
      })
    },
    async updateSharedListItem({ commit, state }, item) {
      let items = JSON.parse(JSON.stringify(state.sharedList.items))
      let updated = false
      items.map(i => {
        if (i.title === item.title) {
          i.done = item.done
          updated = true
        }
      })
      if (updated) {
        await fb.db.collection('share').doc(state.sharedList.id).update({
          items: items
        })
        commit('setStatus', 'Items updated in shared list')
      }
    },
    async resetList ({ commit }) {
      try {
        const listInfo = {
          id: uuidv4(),
          name: 'My Flylist'
        }
        await fb.db.collection('accounts').doc(profile.uid()).update({
          list: {
            id: listInfo.id,
            name: listInfo.name,
            items: []
          }
        })
        commit('setListInfo', listInfo)
        commit('setListItems', [])
      } catch (err) {
        commit('setStatus', 'Failed to create a new list')
      }
    },
    async addToList({ commit }, item) {
      let items = []
      let foundItem = null
      await fb.db.collection('accounts').doc(profile.uid()).get().then(doc => {
        if (doc.data().list && doc.data().list.items) {
          items = doc.data().list.items
          foundItem = doc.data().list.items.find(i => i.title === item.title)
        }
      })
      if(!foundItem) {
        await fb.db.collection('accounts').doc(profile.uid()).update({
          "list.items": fb.firestore.FieldValue.arrayUnion({
            quantity: 1,
            title: item.title,
            done: false
          })
        })
      } else {
        items[items.findIndex(i => i.title === foundItem.title)].quantity = foundItem.quantity + 1
        await fb.db.collection('accounts').doc(profile.uid()).update({
          "list.items": items
        })
      }
      commit('setStatus', 'Item added to list')
    },
    async updateListName ({ commit }, name) {
      await fb.db.collection('accounts').doc(profile.uid()).update({
        "list.name": name
      })
      commit('setStatus', 'List name updated')
    },
    async removeFromHistory ({ commit }, item) {
      await fb.db.collection('accounts').doc(profile.uid()).update({
        history: fb.firestore.FieldValue.arrayRemove(item)
      })
      commit('setStatus', 'Item removed from history')
    },
    async removeFromList({ commit }, item) {
      let foundItem = null
      let foundItemIndex = -1
      let items = []
      await fb.db.collection('accounts').doc(profile.uid()).get().then( doc => {
        items = doc.data().list.items
        foundItem = doc.data().list.items.find(i => i.title === item.title)
        foundItemIndex = doc.data().list.items.findIndex(i => i.title === item.title)
      })
      if (foundItem.quantity > 1) {
        items[foundItemIndex].quantity = foundItem.quantity - 1
        await fb.db.collection('accounts').doc(profile.uid()).update({
          "list.items": items
        })
      } else {
        await fb.db.collection('accounts').doc(profile.uid()).update({
          "list.items": fb.firestore.FieldValue.arrayRemove(foundItem)
        })
      }
      commit('setStatus', 'Item removed from list')
    },
    async clearList({ commit }) {
      await fb.db.collection('accounts').doc(profile.uid()).update({
        "list.items": []
      })
      commit('setStatus', 'Items removed from list')
    },
    async addToHistory({ commit, state }) {
      await fb.db.collection('accounts').doc(profile.uid()).update({
        history: fb.firestore.FieldValue.arrayUnion(state.historyQuery)
      })
      commit('setHistoryQuery', '')
      commit('setStatus', 'Catalog item created')
    },
    async createContact({ commit }, contact) {
      await fb.db.collection('accounts').doc(profile.uid()).update({
        contacts: fb.firestore.FieldValue.arrayUnion(contact)
      })
      commit('setStatus', 'New contact created')
    },
    async shareList({dispatch, commit , state}, contact) {
      const now = fb.firestore.Timestamp.fromDate(new Date())
      await fb.db.collection('accounts').doc(profile.uid()).collection('shared').doc(state.listInfo.id).set({
        name: state.listInfo.name,
        sharedAt: now,
        sharedWith: contact
      })

      await fb.db.collection('share').doc(state.listInfo.id).set({
        id: state.listInfo.id,
        name: state.listInfo.name,
        sharedAt: now,
        sharedWith: contact,
        items: state.listItems
      })
      dispatch('resetList')
      commit('setStatus', 'List shared')
    },
    async deleteSharedList({commit, state}, listId) {
      await fb.db.collection('accounts').doc(profile.uid()).collection('shared').doc(listId).delete()
      await fb.db.collection('share').doc(listId).delete()
      commit('setSharedList', {})
      const filtered = state.sharedLists.filter(i => { return i.id !== listId })
      commit('setSharedLists', filtered)
      commit('setStatus', 'Shared list deleted')
    },
    validateAuth({ commit, state }) {
      if (!state.userProfile) return Promise.resolve(null)
      const user = getAuthenticatedUser()
      commit('setUserProfile', user)
      return user
    },
  },
  getters: {
    historyFilteredByKeyword: state => {
      const search = state.historyQuery.trim().toLowerCase()
      let result = []

      if (!search.length) {
        if (state.sort === 'alpha') {
          result = state.history.sort()  
        } else {
          result = state.history.slice().reverse()
        }
      } else {
        result = state.history.filter(item => item.toLowerCase().indexOf(search) > -1)
      }
      return result
    },
    loggedIn(state) {
      return !!state.userProfile
    },
  }
})

export default store