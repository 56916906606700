<template>
  <div class="">
    <swipe-list ref="list" class="p-2" :items="items" item-key="id" 
      :threshold="90"
      @leftRevealed="setLastEvent('add', $event)"
      @rightRevealed="setLastEvent('forget', $event)"
      >
      <template v-slot="{ item }">
        <div class="border-b border-pink-100 py-4 px-3 font-light">{{ item }}</div>
      </template>
      <template v-slot:left="{ }">
        <div class="p-4 bg-blue-700 text-white" title="Add Item">
          <div class="w-12 py-1.5 uppercase text-xs text-center">Add</div>
        </div>
        <div class="p-4">
          <div style="width:30px"></div>
        </div>
      </template>
      <template v-slot:right="{ }">
        <div class="p-4">
          <div style="width:100px"></div>
        </div>
        <div class="p-4 bg-red-700 text-white">
          <div class="uppercase text-xs w-28 py-1.5">Forget</div>
        </div>
      </template>
    </swipe-list>
  </div>
</template>

<script>
import { SwipeList, SwipeOut } from 'vue-swipe-actions';

export default {
  name: 'ListItems',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SwipeOut,
    SwipeList,
  },
  computed: {
    items () {
      return this.$store.getters.historyFilteredByKeyword
    }
  },
  methods: {
    setLastEvent(name, { item }) {
      if (name === 'add') {
        this.addToList(item)
      }
      if (name === 'forget') {
        this.removeFromHistory(item)
      }
      this.closeAll()
    },
    addToList(title) {
      this.$store.dispatch('addToList', {
        title: title,
        quantity: 1,
        done: false
      })
    },
    removeFromHistory(item) {
      this.$store.dispatch('removeFromHistory', item)
    },
    closeAll() {
      this.$refs.list.closeActions()
    }
  }
}
</script>
