<template>
  <button class="btn-icon active:translate-y-1" @click="onSort">
    <Icon v-if="sort" name="sort-by-alpha.svg" />
    <Icon v-else name="sort.svg" />
  </button>
</template>

<script>
import Icon from '@/components/Icon'
import { mapState } from 'vuex'

export default {
  components: {
    Icon
  },
  computed: {
    ...mapState(['sort']),
  },
  methods: {
    onSort() {
      this.sort ? this.$store.commit('setSort', null) : this.$store.commit('setSort', 'alpha') 
    }
  }
}
</script>
