<template>
  <div class="w-80 mx-auto p-10">
    <h2 class="uppercase">Sign In</h2>
    <input type="email" class="block w-full my-3 outline-none p-2 border focus:placeholder-pink-500" placeholder="Email address" v-model.trim="form.email">
    <input type="password" class="block w-full outline-none p-2 border focus:placeholder-pink-500" placeholder="Password" v-model.trim="form.password">
    <button class="btn-blue my-3 block mx-auto" @click="login()">Sign in</button>
    <div class="font-medium text-pink-600">{{error}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login() {
      this.$store.commit('setError', '')
      this.$store.dispatch('login', {
        email: this.form.email,
        password: this.form.password
      })
    }
  },
  computed: {
    error() {
      return this.$store.state.error
    }
  }
}
</script>
