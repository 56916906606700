<template>
  <div class="text-center pt-10">
    <img src="../assets/img/logo.svg" class="mx-auto w-20" alt="Flylist Logo">
    <img src="../assets/img/flylist.svg" class="mx-auto" alt="Flylist">
    <p class="text-xl px-3">Super fast way to create and share actionable lists with anyone</p>
    <SignIn />
    <footer class="fixed bottom-0 text-right w-full opacity-50 px-2 text-xs">v. 20200909</footer>
  </div>
</template>

<script>
import SignIn from '@/components/SignIn'

export default {
  name: 'Login',
  components: {
    SignIn
  }
}
</script>
