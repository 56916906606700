const uid = () => {
  return JSON.parse(localStorage.getItem('profile')).uid
}

const email = () => {
  return JSON.parse(localStorage.getItem('profile')).email
}

export {
  uid,
  email
}