<template>
  <div class="grid h-screen" style="grid-template-rows: 55px 1fr 55px;">
    <header class="grid border-b bg-gray-100" style="grid-template-columns: 50px auto 50px 50px;">
      <div>
        <button class="btn-icon active:translate-y-1" @click="focusOnSearch"><Icon name="search-outline.svg"/></button>
      </div>
      <Search ref="search"/>
    <div>
      <SortButton />
    </div>
      <div>
        <button class="btn-icon active:translate-y-1" @click="onAdd"><Icon name="add.svg"/></button>
      </div>
    </header>
    <main class="overflow-auto">
      <ListItems />
      <div class="text-center p-8 text-sm opacity-40 grid grid-cols-3">
        <img src="@/assets/icons/one-finger-swipe-left.svg" alt="Swioe left" class="justify-self-end">
        <span>Swipe left or right for actions</span>
        <img src="@/assets/icons/one-finger-swipe-right.svg" alt="Swioe right" class="justify-self-start">
      </div>
    </main>
    <AppFooter/>
  </div>
</template>

<script>
import Search from '@/components/Search'
import AppFooter from '@/components/AppFooter'
import ListItems from '@/components/ListItems'
import SortButton from '@/components/SortButton'
import Icon from '@/components/Icon'

export default {
  name: 'Home',
  components: {
    Search,
    AppFooter,
    ListItems,
    SortButton,
    Icon
  },
  methods: {
    onAdd() {
      if(this.$store.state.historyQuery) {
        this.$store.dispatch('addToList', {
          title: this.$store.state.historyQuery,
          quantity: 1,
          done: false
        })
        this.$store.dispatch('addToHistory')
      }
    },
    focusOnSearch () {
      this.$refs.search.focus()
    }
  },
  mounted() {
    this.$store.dispatch('fetchHistory')
    this.$store.dispatch('fetchList')
  }
}
</script>
