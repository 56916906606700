<template>
  <img :src="require(`@/assets/icons/${name}`)" alt="icon" class="inline-block w-full" :style="{ opacity: opacity }">
</template>

<script>
export default {
  props: {
    name: String,
    opacity: String
  }
}
</script>

<style scoped>

</style>