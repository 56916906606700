<template>
  <footer class="grid grid-cols-4 justify-items-center border-t">
    <div>
      <router-link to="/" exact v-slot="{href, navigate, isActive, isExactActive }" custom>
        <button :href="href" @click="navigate" class="btn-icon active:translate-y-1" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><Icon name="search-outline.svg" /></button>
      </router-link>
    </div>
    <div>
      <sup>{{count}}</sup>
      <router-link to="/list" v-slot="{href, navigate, isActive, isExactActive }" custom>
        <button :href="href" @click="navigate" class="btn-icon active:translate-y-1" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><Icon name="document-list.svg" /></button>
      </router-link>
    </div>
    <div>
      <router-link to="/lists" v-slot="{href, navigate, isActive, isExactActive }" custom>
        <button :href="href" @click="navigate" class="btn-icon active:translate-y-1" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><Icon name="history.svg" /></button>
      </router-link>
    </div>
    <div>
      <router-link to="/profile" v-slot="{href, navigate, isActive, isExactActive }" custom>
        <button :href="href" @click="navigate" class="btn-icon active:translate-y-1" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><Icon name="person.svg"  /></button>
      </router-link>
    </div>
  </footer>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: "AppFooter",
  components: {
    Icon
  },
  computed: {
    count() {
      return this.$store.state.listItems.length ? this.$store.state.listItems.length : ''
    }
  }
}
</script>

<style scoped>
.router-link-active,
.router-link-exact-active {
  border-bottom: 2px solid #133155;
  cursor: pointer;
}
sup {
  position: relative;
  left: 50px;
  top:  -9px;
  color: #333;
  letter-spacing: 1.5px;
  display: inline-block;
  width: 20px;
  text-align: center;
}
</style>