var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"grid grid-cols-4 justify-items-center border-t"},[_c('div',[_c('router-link',{attrs:{"to":"/","exact":"","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('button',{staticClass:"btn-icon active:translate-y-1",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('Icon',{attrs:{"name":"search-outline.svg"}})],1)]}}])})],1),_c('div',[_c('sup',[_vm._v(_vm._s(_vm.count))]),_c('router-link',{attrs:{"to":"/list","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('button',{staticClass:"btn-icon active:translate-y-1",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('Icon',{attrs:{"name":"document-list.svg"}})],1)]}}])})],1),_c('div',[_c('router-link',{attrs:{"to":"/lists","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('button',{staticClass:"btn-icon active:translate-y-1",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('Icon',{attrs:{"name":"history.svg"}})],1)]}}])})],1),_c('div',[_c('router-link',{attrs:{"to":"/profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('button',{staticClass:"btn-icon active:translate-y-1",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('Icon',{attrs:{"name":"person.svg"}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }