import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '@/store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/list',
    component: () => import(/* webpackChunkName: "list" */ '../views/List.vue'),
    children: [
      {
        path: '',
        name: 'list',
        component: () => import(/* webpackChunkName: "listIndex" */ '../views/ListIndex.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'share',
        name: 'list-share',
        component: () => import(/* webpackChunkName: "listShare" */ '../views/ListShare.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/lists',
    name: 'shared-lists',
    component: () => import(/* webpackChunkName: "shared-lists" */ '../views/SharedLists.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/share/:id',
    name: 'share',
    component: () => import(/* webpackChunkName: "share" */ '../views/Share.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(x => x.meta.requiresAuth)

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  // If auth is required and the user is logged in...
  if (store.getters['loggedIn']) {
    // Validate the local user token...
    return store.dispatch('validateAuth').then((validUser) => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        validUser ? next() : redirectToLogin()
    })
  }

  function redirectToLogin() {
    // Pass the original route to the login component
    next({
        name: 'login',
        query: {
            redirectFrom: to.fullPath
        }
    })
  }
})

export default router
