<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'App',
}
</script>

<style>
#id {
  width: 100%;
  height: 100%;
}
</style>
