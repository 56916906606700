<template>
  <div>
    <input 
      type="text" 
      ref="search" 
      v-model="historyQuery" 
      placeholder="Type here to search or add new item..." 
      class="h-full w-full px-3 focus:placeholder-pink-500 outline-none placeholder-gray-400"
    />
  </div>
</template>

<script>
export default {
  name: 'Search',
  computed: {
    historyQuery: {
      get () {
        return this.$store.state.historyQuery
      },
      set (value) {
        this.$store.commit('setHistoryQuery', value)
      }
    }
  },
  methods: {
    focus () {
      this.$refs.search.focus()
    }
  }
}
</script>
