import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
}
firebase.initializeApp(firebaseConfig)

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    localStorage.setItem("profile", JSON.stringify(user))
  } else {
    localStorage.removeItem('profile')
  }
})

const getAuthenticatedUser = () => {
  if (!localStorage.getItem('profile')) {
    return null
  }
  return JSON.parse(localStorage.getItem('profile'))
}

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const firestore = firebase.firestore

export {
  db,
  auth,
  firestore,
  getAuthenticatedUser
}